import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';
import VendorPartnerLogos from '../components/vendor-partner-logos';

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Calendar = () => (
  <Layout>
    <SEO title="Calendar" />
    <Title>IOLCF Event Calendar</Title>
    <CalendarContainer>
      <iframe
        frameoBorder="0"
        height="600"
        scrolling="no"
        src="https://calendar.google.com/calendar/embed?src=iolcfevents%40gmail.com&ctz=America%2FNew_York"
        style={{ border: '0' }}
        title="IOLCF Google Calendar"
        width="800"
      />
    </CalendarContainer>
    <VendorPartnerLogos />
  </Layout>
);

export default Calendar;
